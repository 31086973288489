
// import { Component, Vue } from "vue-property-decorator";
import GPUser from "@/data/GPUser";
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import { mapState } from "vuex";
import CircleImage from "@/components/modular-page/content-element/CircleImage.vue";
import { useRoute } from "vue-router";

interface BasicUser {
	firstname?: string;
	username?: string;
}

// const showGlobalHeader = ref(true);

// onMounted(() => {
// 		console.log("Global Header onMounted!");
// 		if (window.innerWidth < 451) {
// 			showGlobalHeader.value = true;
// 		}
// 		window.addEventListener("resize", () => {
// 			console.log("window resized");
// 			showGlobalHeader.value = window.innerWidth < 451;
// 			console.log("showGlobalHeader " + showGlobalHeader.value);
// 		});
// });

export default defineComponent({
	// props: {
	//   user: {
	//     type: Object as PropType<BasicUser>,
	//     required: true,
	//   },
	// },
	components: {
		CircleImage,
	},
	props: {
		mainTopHeader: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	setup(props) {
		const smallSizeScreen = ref(false);
		const route = useRoute();

		var pathname = ref(window.location.pathname);

    //Watch for route changes so that we can update the pathname
    //When the pathname updates the computed function showFooter
    //updates which updates the v-if for showing the footer
    watch(
      () => route.path,
      async (newPath) => {
        pathname.value = window.location.pathname;
        console.log("Route Changed " + pathname.value);
      }
    );
		
		const showGlobalHeader = computed(() => {	
			if (props.mainTopHeader) {
				var path = pathname.value;
				//console.log("path " + path);
				var comps = path.split("/");
				if (comps.length > 1) {
					var firstPart = comps[1];
					//console.log("checking showGlobalHeader firstPart:" + firstPart + " id:" + props.fullScreenPageId + " smallSizeScreen:" + smallSizeScreen.value);
					if (firstPart == "share" || firstPart == "map") {
						return smallSizeScreen.value;
					}
				}
			}
      return true;			
    });


		onMounted(() => {
			smallSizeScreen.value = window.innerWidth < 451;

			window.addEventListener("resize", () => {
				smallSizeScreen.value = window.innerWidth < 451;
			});
		});
		return {
			showGlobalHeader
		}
	},
	data() {
		return {
			showCollapsed: false,
		};
	},
	computed: {
		...mapState("account", ["status", "user"]),
		unameFull(): string {
			return this.user.firstname || this.user.username || "";
		},
		isAdmin(): boolean {
			if (this.user) {
				return this.user.isAdmin;
			}
			return false;
		},
		usrImgURL(): string {
			return GPUser.getAvatarURL(this.user);
		},
		shouldShowAvatar(): boolean {
			return (
				this.status.loggedIn && this.usrImgURL !== "/img/default-user-img.webp"
			);
		},
	},
	// mounted() {
	// 	console.log("Global Header onMounted!");
	// 	//showGlobalHeader.value  = window.innerWidth < 451;
	// 	console.log("showGlobalHeader in mounted " + this.showGlobalHeader);

	// 	window.addEventListener("resize", () => {
	// 		console.log("window resized");
	// 		this.showGlobalHeader = window.innerWidth < 451;
	// 		console.log("showGlobalHeader " + this.showGlobalHeader);
	// 	});
	// },
});
